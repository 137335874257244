// eslint-disable-next-line no-unused-vars
const SubjectDataHeaders = (_this) => {
  return {
    countCells: 12,
    massive: [
      {
        dataField: 'subject',
        cols: 2,
        rows: 1,
        text: 'Субъект РФ',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'nps',
        cols: 2,
        rows: 1,
        text: 'НПС',
        // order: 0,
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'placesOchno',
        cols: 2,
        rows: 1,
        text: 'Кол-во очных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'placesOcnhoZaochno',
        cols: 2,
        rows: 1,
        text: 'Кол-во очно-заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'placesZaochno',
        cols: 2,
        rows: 1,
        text: 'Кол-во заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      // {
      //   dataField: 'actions',
      //   cols: 1,
      //   rows: 1,
      //   text: '',
      //   variable: true,
      //   addButton: true,
      // },
      {
        dataField: 'scan',
        cols: 2,
        rows: 1,
        text: 'Наличие скана',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      // {
      //   dataField: 'client',
      //   cols: 2,
      //   rows: 1,
      //   text: 'Заказчик',
      //   sortable: true,
      //   typeCell: 'string',
      //   variable: true,
      //   class: 'text-center',
      //   headerClass: 'text-center',
      // },
      // {
      //   dataField: 'organization',
      //   cols: 1,
      //   rows: 1,
      //   text: 'Организация трудоустройства',
      //   sortable: true,
      //   typeCell: 'string',
      //   variable: true,
      //   class: 'text-center',
      //   headerClass: 'text-center',
      // },
      // {
      //   dataField: 'innerActions',
      //   cols: 1,
      //   rows: 1,
      //   text: '',
      //   variable: true,
      //   addButton: false,
      // },
    ],
  };
}
export default SubjectDataHeaders