<template>
  <div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">Информация о введенных значениях
        Субъектами РФ</h2>
    </header>
    <wrapper-preview-category customClass="section-preview_lk">
      <GridTable
        :data-rows="subjectData"
        :headers="tableHeaders.massive"
        :headers-for-rows="tableHeaders.massive"
        :num-cols="tableHeaders.countCells"
        :loading-data="loading"
        max-table-height="700px"
      >
        <template #nps="{ row }">
          <div>{{ `(${row.sprSpec.specCode}) ${row.sprSpec.specName}` }}</div>
        </template>
        <template #subject="{ row }">
          <div>{{ subject(row.sprRegion) }}</div>
        </template>
        <template #placesOchno="{ row }">
          <div>{{ row.placesOchno }}</div>
        </template>
        <template #placesOcnhoZaochno="{ row }">
          <div>{{ row.placesOcnhoZaochno }}</div>
        </template>
        <template #placesZaochno="{ row }">
          <div>{{ row.placesZaochno }}</div>
        </template>
        <template #scan="{ row }">
          <v-icon
            v-if="scanCheck(row)"
            color="primary" size="24">mdi-check-bold
          </v-icon>
        </template>
        
        <!--                <template #scan="{ row }">-->
        <!--                  <div>{{ getUploadedScan(row.sprRegion, row) }}</div>-->
        <!--                </template>-->
      </GridTable>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import GridTable from "@/components/GridTable/GridTable";
import SubjectDataHeaders from "@/components/GridTable/dataFolder/SubjectDataHeaders";

export default {
  name: "SubjectsData",
  components: {
    GridTable,
    WrapperPreviewCategory
  },
  data: () => ({
    tableHeaders: [],
    regionSelectorArray: [],
    subjectData: [],
    loading: false,
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
  }, // /api/collection/region/getAll
  created() {
    this.tableHeaders = SubjectDataHeaders(this);
    this.getAllSprRegion();
    this.getAllSubjectDataByRegion();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    
    async getAllSubjectDataByRegion() {
      this.loading = true;
      try {
        const res = await request({
          endpoint: `/api/collection/region/getAll?founderId=${this.getUser.founderID}`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          console.log('getAllSubjectDataByRegion успех');
          this.subjectData = res.data;
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async getAllSprRegion() {
      try {
        const res = await request({
          endpoint: `/api/spr/region/getAll`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].regionName && res.data[i].regionId) {
              this.regionSelectorArray.push({
                text: res.data[i].regionName,
                value: res.data[i].regionId,
                founderId: res.data[i].founderStatus?.id,
                scanFiles: res.data[i].users?.scanFiles
              })
            }
          }
          console.log('getAllSprRegion успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    subject(id) {
      const x = this.regionSelectorArray.find(el => el.value === id || el.value === id.regionId);
      return x?.text
    },
    
    scanCheck(row) {
      if (row.sprRegion?.users?.scanFiles.find(el => el.scanType === 'MAIN_SCAN_2022' || el.scanType === 'LETTER_OUT_OF_SCAN_2022')) {
        return true
      } else if (this.regionSelectorArray.find(el => el.value === row.sprRegion)?.scanFiles.find(el => el.scanType === 'MAIN_SCAN_2022' || el.scanType === 'LETTER_OUT_OF_SCAN_2022')) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>

<style scoped>

</style>